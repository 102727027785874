<template>
    <span ref="trigger"/>
</template>

<script>
export default {
    props: {
        treshold: {
            type: Number,
            default: 0,
        },
        margin: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            lastStatus: null,
            observer: null,
        };
    },
    mounted() {
        if (('IntersectionObserver' in window)) {
            this.observer = new IntersectionObserver((entries) => {
                this.handleIntersect(entries[0]);
            }, {
                root: null,
                threshold: this.treshold,
                rootMargin: `${this.margin}px`,
            });

            this.observer.observe(this.$refs.trigger);
        }
    },
    beforeDestroy() {
        if (this.observer) {
            this.observer.disconnect();
        }
    },
    methods: {
        handleIntersect(entry) {
            if (entry.isIntersecting !== this.lastStatus) {
                this.$emit('intersect-change', entry.isIntersecting);
                this.lastStatus = entry.isIntersecting;
            }
        },
    },
};
</script>
